import { createSignal } from "solid-js";

import ListTile from "@repo/ui/info/ListTile";
import Autocomplete from "@repo/ui/inputs/Autocomplete";
import FullName from "@repo/utils/FullName";
import PhoneNumber from "@repo/utils/PhoneNumber";
import AppRouter from "@repo/utils-solid/AppRouter";

import { useUsers } from "~/state/features/users";

import Status from "./Status";

declare namespace UserSearch {
  type Props = {};
}

function UserSearch({}: D<UserSearch.Props>) {
  const [users] = useUsers();
  const [query, setQuery] = createSignal("");

  const userEntries = () => {
    const userList = users.query(query());
    if (!userList || userList.length == 0) {
      const noSuggestions: Autocomplete.Suggestion = {
        value: "0",
        content: <ListTile class="m-0" align="center" title="No Results" />,
      };
      return [noSuggestions];
    }

    const userEntries = userList.map((user) => ({
      value: user.id,
      content: (
        <ListTile
          class="m-0"
          align="center"
          leading={
            <Status color={user.active ? "var(--success)" : "var(--error)"} />
          }
          title={FullName.format(user.pii.name)}
          subtitle={
            <>
              <span> {user.pii.email}</span>
              <span class="inline-block ml-1.5">
                 {PhoneNumber.format(user.pii.phone)}
              </span>
            </>
          }
        />
      ),
    }));

    return userEntries;
  };

  return (
    <Autocomplete
      label="Search users"
      suggestions={query() == "" ? [] : userEntries()}
      onApplySuggestion={(suggestion) => {
        AppRouter.push(`/users/${suggestion.value}`);
      }}
      onInput={(e) => {
        setQuery(e.currentTarget.value);
      }}
    />
  );
}

export default UserSearch;
