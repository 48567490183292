import { Accessor, createComputed, createSignal, untrack } from "solid-js";

export default function createRetained<T>(value: Accessor<T>): Accessor<T> {
  const [retained, setRetained] = createSignal(untrack(value));

  createComputed(() => {
    if (value()) setRetained(value() as any);
  });

  return retained;
}
