import c from "class-c";

import styles from "./Status.module.scss";

declare namespace Status {
  type Props = {
    class?: string;
    color: string;
  };
}

function Status({ class: className, color }: D<Status.Props>) {
  return (
    <span
      class={c`${styles.status} ${className}`}
      style={{ background: color }}
    />
  );
}

export default Status;
