import { Accessor, createComputed, createSignal, untrack } from "solid-js";

export default function createDelayed<T>(
  accessor: Accessor<T>,
  delayMs: number,
) {
  const [value, setValue] = createSignal(untrack(accessor));

  createComputed(() => {
    const value = accessor();

    setTimeout(() => {
      setValue(() => value);
    }, delayMs);
  });

  return value;
}
