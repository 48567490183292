import {
  ComponentProps,
  createEffect,
  createSignal,
  JSX,
  untrack,
} from "solid-js";
import c from "class-c";
import { eventHandler } from "solid-u";
import {
  createInputMask,
  createMaskPattern,
} from "@solid-primitives/input-mask";

import createDelayed from "@repo/signals/createDelayed";
import createId from "@repo/signals/createId";
import createRetained from "@repo/signals/createRetained";
import UObject from "@repo/utils/UObject";
import createPropsProvider from "@repo/utils-solid/createPropsProvider";

import Expand from "../animation/Expand";
import Fade from "../animation/Fade";
import ContainerButton from "../buttons/ContainerButton";

import styles from "./TextInput.module.scss";

declare namespace TextInput {
  type Props = UObject.Override<
    ComponentProps<"input">,
    {
      ref?: ComponentProps<"label">["ref"];
      label: string;
      format?(str: string): string;
      error?: boolean | string | Error;
      action?: JSX.Element;
      mask?: Props["onInput"] | string;
    }
  >;
}

function TextInput(_props: TextInput.Props) {
  const {
    class: className,
    label,
    onInput,
    onFocus,
    onBlur,
    onKeyDown,
    format = (s) => s,
    placeholder,
    error,
    ref,
    action,
    id = createId(),
    mask,
    ...props
  } = TextInput.PropsProvider.useMerge(_props) satisfies D;

  const [open, setOpen] = createSignal(!!props.value);
  const [focused, setFocused] = createSignal(false);

  const showPlaceholder = createDelayed(open, 50);

  createEffect(() => {
    setOpen(!!props.value || untrack(focused));
  });

  return (
    <label
      ref={ref}
      class={c`
        ${styles["text-input"]}
        ${className}
      `}
    >
      <main
        class={c(styles)`
          ${{ open, focused, error }}
        `}
      >
        <header>{label}</header>
        {/* Input mask label */}
        <label for={id} />
        <input
          ref={(input) => {
            input.addEventListener(
              "input",
              // @ts-expect-error onInput type doesn't match "input" event listener type
              typeof mask === "string"
                ? createMaskPattern(
                    createInputMask(mask),
                    () => placeholder as string,
                  )
                : eventHandler(mask),
            );
          }}
          id={id}
          type="text"
          placeholder={showPlaceholder() ? placeholder : undefined}
          onInput={(e) => {
            const { value } = e.currentTarget;

            if (!focused()) setOpen(!!value);

            e.currentTarget.value = format(value);

            eventHandler(onInput)(e);
          }}
          onFocus={(e) => {
            setOpen(true);
            setFocused(true);

            eventHandler(onFocus)(e);
          }}
          onBlur={(e) => {
            if (e.currentTarget) setOpen(!!e.currentTarget.value);
            setFocused(false);

            eventHandler(onBlur)(e);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              const action = props.enterkeyhint;
              if (action === "go") {
                e.currentTarget.form?.dispatchEvent(new Event("submit"));
                e.currentTarget.blur();
              }
            }

            eventHandler(onKeyDown)(e);
          }}
          {...props}
        />
        {action}
      </main>
      {(() => {
        const message = () => {
          const e = error;
          return !!e && e !== true && (e instanceof Error ? e.message : e);
        };
        const retained = createRetained(message);

        return (
          <Expand direction="y" show={message()}>
            <Fade
              show={message()}
              transition="var(--normal-s) calc(var(--normal-s) / 2)"
            >
              <div class="text:small px-1 pt-0.5 color:error border-box">
                {retained()}
              </div>
            </Fade>
          </Expand>
        );
      })()}
    </label>
  );
}

TextInput.PropsProvider = createPropsProvider<TextInput.Props>("TextInput");

declare namespace TextInput.Action {
  export type Props = ContainerButton.Props & {};
}

TextInput.Action = Action;
function Action({
  class: className,
  children,
  ...props
}: D<TextInput.Action.Props>) {
  return (
    <ContainerButton
      tabIndex={-1}
      class={c`${styles["text-input-action"]} ${className}`}
      {...props}
    >
      {children}
    </ContainerButton>
  );
}

declare namespace TextInput.Action.Visibility {
  export type Props = TextInput.Action.Props & {
    onClick(): void;
    hidden: boolean;
  };
}

Action.Visibility = ({
  onClick,
  hidden,
}: D<TextInput.Action.Visibility.Props>) => {
  return (
    <TextInput.Action onClick={onClick} class="icon:medium color:moderate">
      {hidden ? "" : ""}
    </TextInput.Action>
  );
};

export default TextInput;
