import { JSX } from "solid-js";

import createInitial from "../signals/createInitial";

declare namespace Fade {
  type Direction = keyof typeof translationByDirection;

  interface Props {
    class?: string;
    style?: JSX.CSSProperties;
    children: JSX.Element;
    transition?: JSX.CSSProperties["transition"];
    direction?: Direction;
    show?: unknown;
  }
}

const translationByDirection = {
  up: [0, 1],
  right: [1, 0],
  down: [0, -1],
  left: [-1, 0],
} as const;

function Fade({
  show = true,
  direction,
  transition,
  style,
  ...props
}: D<Fade.Props>) {
  const initial = createInitial();

  return (
    <div
      style={{
        transition: transition || "var(--normal-s)",
        opacity: !initial() && show ? 1 : 0,
        transform: direction
          ? (() => {
              const [x, y] = translationByDirection[direction];
              return `translate(${x * 100}%, ${y * 100}%)`;
            })()
          : undefined,
        ...style,
      }}
      {...props}
    />
  );
}

export default Fade;
