import UNumber from "@repo/utils/UNumber";

namespace UKeyboardEvent {
  const directionByKey = {
    ArrowUp: 1,
    ArrowRight: 1,
    ArrowDown: -1,
    ArrowLeft: -1,
  } as const;

  export const arrowDirection = (e: KeyboardEvent): UNumber.Sign =>
    e.key in directionByKey
      ? directionByKey[e.key as keyof typeof directionByKey]
      : 0;
}

export default UKeyboardEvent;
