import UString from "./UString.mjs";

namespace PhoneNumber {
  export const format = (phone: string | undefined | null) => {
    if (!phone) return "";

    phone = phone.replace(/\D/g, "");

    const [ext, last10] = UString.splitAt(phone, -10);
    return `${ext ? `+${ext} ` : ""}(${last10.slice(0, 3)}) ${last10.slice(3, 6)}-${last10.slice(6, 10)}`;
  };
}

export default PhoneNumber;
